<template>
  <div class="events container" v-if="events.length">
    <h2 class="subtitle is-3">Upcoming Events</h2>
    <div class="columns is-multiline is-centered">
      <div
        v-for="event in events"
        :key="event.id"
        class="column is-flex is-justify-content-center"
      >
        <router-link :to="`/event/${event.id}`">
          <EventCard :event="event" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import EventCard from "@/components/EventCard";
import EventService from "../services/eventService";

export default {
  name: "EventsList",
  components: {
    EventCard,
  },
  data() {
    return {
      events: [],
    };
  },
  async mounted() {
    const events = await EventService.getAll();
    this.events = events.filter((event) => event.visible === true);
  },
};
</script>

<style lang="scss" scoped>
.events {
  margin-top: 50px;
  text-align: center;

  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

.columns {
  justify-content: center; /* Center the columns */
}
</style>
