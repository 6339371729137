import routes from "./router/routes";
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';

// Import only necessary parts from FontAwesome and Bulma
import '@fortawesome/fontawesome-free/css/all.css'; // Only CSS
import './../node_modules/bulma/css/bulma.css'; // Bulma styles

// Import SweetAlert2 for popups
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// Import Router and History
import { createRouter, createWebHistory } from 'vue-router';

// Create the app instance
const app = createApp(App);

// Create Router Instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Pinia Store
const pinia = createPinia();
app.use(pinia);

// Authentication Guard
router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  
  if (to.meta.requiresAuth && !isLoggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
});

// Use SweetAlert2 for notifications
app.use(VueSweetalert2);

// Apply the router and mount the app
app.use(router).mount('#app');
