import { db } from "../firebase"

class UserService {
    async getAll() {
        const snapshot = await db.collection("users").get()
        const documents = [];
        snapshot.forEach(doc => {
            const document = { id: doc.id, ...doc.data() };
            documents.push(document);
        })
        return documents;
    }
    async get(id){
        const event = await db.collection("users").doc(id).get()
        return event.data()
    }
    async getByEmail(email){
        const allUsers = await this.getAll();
        const usersWithSameEmail = allUsers.filter(user => user.email === email)
        if (usersWithSameEmail.length === 0){
            console.error(`User with email ${email} does not exist.`)
        }
        else{
            return usersWithSameEmail[0];
        }
        return null;
    }

    create(event) {
        db.collection("users").add(event)
            .then((docRef) => {
                console.log(`Document written with ID: ${docRef.id}`)
            })
            .catch((error) => {
                console.error(`Error adding document: ${error}`)
            })
    }

    update(id, value) {
        db.collection("users").doc(id).update(value)
            .then(docRef => {
                console.log(`Document updated: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }

    delete(id) {
        db.collection("users").doc(id).delete()
            .then(docRef => {
                console.log(`Document deleted: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }
}

export default new UserService();