import { db } from "../firebase"

class NewsletterService {
    async getAll() {
        const snapshot = await db.collection("newsletters").get()
        const documents = [];
        snapshot.forEach(doc => {
            const document = { id: doc.id, ...doc.data() };
            documents.push(document);
        })
        return documents;
    }
    async get(id){
        const newsletter = await db.collection("newsletters").doc(id).get()
        return newsletter.data()
    }
    async getLastNewsletter(){
        const document = await db.collection("newsletters").orderBy("dateReleased", "desc")
            .limit(1).get()
        const documents = [];
        document.forEach(doc => {
            const document = { id: doc.id, ...doc.data() };
            documents.push(document);
        })
        return documents;
    }
    create(newsletter) {
        db.collection("newsletters").add(newsletter)
            .then((docRef) => {
                console.log(`Document written with ID: ${docRef.id}`)
            })
            .catch((error) => {
                console.error(`Error adding document: ${error}`)
            })
    }

    update(id, value) {
        db.collection("newsletters").doc(id).update(value)
            .then(docRef => {
                console.log(`Document updated: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }

    delete(id) {
        db.collection("newsletters").doc(id).delete()
            .then(docRef => {
                console.log(`Document deleted: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }
}

export default new NewsletterService();