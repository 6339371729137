<template>
  <div class="home">
    <section class="hero is-dark">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">Welcome to the Polish Folk Dance Association of the Americas</h1>
        </div>
      </div>
    </section>
    <EventsList />
    <Newsletter />
    <Ensembles />
  </div>
</template>

<script>
import EventsList from "../components/EventsList";
import Newsletter from "../components/Newsletter";
import Ensembles from "../components/Ensembles";

export default {
  name: "home",
  components: {
    EventsList,
    Newsletter,
    Ensembles,
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("https://pfdaa-app-uploads-public.s3.amazonaws.com/polishflags.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%; /* Use full height */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  height:300px;

  @media screen and (max-width: 768px) {
    height: auto; /* Adjust height on mobile */
  }
}

.hero-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure container fills the hero */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 48px;
  font-weight: 700;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  color: white;
  max-width: 80%; /* Limit text width */
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    font-size: 28px; /* Smaller text size for mobile */
    max-width: 100%;
  }
}

.subtitle {
  font-size: 24px;
  color: #f4f4f4;
  margin-top: 10px;
}

.button-block {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .button {
    margin-right: 20px;
    padding: 15px 30px;
    border-radius: 25px;
    background-color: #ff6347;
    color: white;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff4500;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    .button {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
      font-size: 1rem;
    }
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
