<template>
  <footer class="footer">
    <div class="content">
      <div class="content has-text-centered">
        <p>
          <strong>The Polish Folk Dance Association of the Americas</strong> is a 501(c)3 non-profit organization
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer"
};
</script>
<style lang="scss" scoped>
footer {
  margin-top: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  bottom: 0;
}
img {
  max-height: 25px;
}
.content {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;justify-content: center;
}
</style>