<template>
  <div class="ensemble-card">
    <div class="card">
      <div class="card-image">
        <figure>
          <img :src="ensemble.featuredImage" alt="Ensemble image" />
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">{{ ensemble.name }}</p>
            <p class="subtitle is-6">{{ ensemble.city }}, {{ ensemble.state }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["ensemble"],
};
</script>

<style lang="scss">
.ensemble-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .card-image {
    height: 200px;
    display: block;
    overflow: hidden;
  }

  .card-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
    border-radius: 10px 10px 0 0;
  }

  .card-content {
    padding: 1.5rem;

    .title {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 0;
      line-height: 1.5;
    }

    /* Explicitly reset the margin-top of the subtitle */
    .subtitle {
      font-size: 0.875rem;
      color: #6b6b6b;
      // margin-top: 0 !important; /* Reset the negative margin */
    }

    /* Specific override for Bulma's rule */
    .title + .subtitle {
      margin-top: 0.25rem !important; /* Add custom positive margin */
    }
  }
}
</style>
