<template>
  <div class="ensembles container">
    <h2 class="subtitle is-3">Our Dance Groups</h2>
    <div class="columns is-multiline">
      <div
        v-for="ensemble in ensembles"
        :key="ensemble.id"
        class="column is-full-mobile is-half-tablet is-one-quarter-desktop"
      >
        <router-link :to="`/ensemble/${ensemble.id}`">
          <EnsembleCard :ensemble="ensemble" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import EnsembleCard from "@/components/EnsembleCard";
import EnsembleService from "../services/ensembleService";

export default {
  name: "Ensembles",
  components: {
    EnsembleCard,
  },
  data() {
    return {
      ensembles: [],
    };
  },
  async mounted() {
    this.ensembles = await EnsembleService.getAll();
  },
};
</script>

<style lang="scss" scoped>
.ensembles {
  margin-top: 50px;
  text-align: center;

  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .columns {
    display: flex;
    justify-content: center;
  }
}
</style>
