import firebase from "firebase/app"; // Import Firebase core
import "firebase/firestore";          // Firestore service
import "firebase/remote-config";      // Remote Config service
import "firebase/auth";               // Auth service

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA7tiPl0ZDpObfWw1i0JnO8rT7gSckMcWk",
  authDomain: "pfdaa-7f092.firebaseapp.com",
  databaseURL: "https://pfdaa-7f092.firebaseio.com",
  projectId: "pfdaa-7f092",
  storageBucket: "pfdaa-7f092.appspot.com",
  messagingSenderId: "893862466268",
  appId: "1:893862466268:web:35813321cc9b6bedd51a19",
  measurementId: "G-HQEYVJ65DF"
};

// Initialize Firebase app
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Initialize Firestore
const db = firebase.firestore();

// Initialize Firebase Auth
const auth = firebase.auth();

// Check if Remote Config is available
let remoteConfig;

if (typeof firebase.remoteConfig === "function") {
  // Initialize Firebase Remote Config if available
  remoteConfig = firebase.remoteConfig();
  
  // Set Remote Config settings
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000, // Set the interval for fetching remote configs (1 hour)
  };

  // Fetch and activate Remote Config parameters
  remoteConfig
    .fetchAndActivate()
    .then(() => {
      console.log("Remote config activated");
    })
    .catch((err) => {
      console.error("Error activating remote config:", err);
    });
} else {
  console.error("Firebase Remote Config is not available");
}

// Export the necessary services
export { db, auth, remoteConfig };
