import {db} from "../firebase"

class EnsembleService {
    async getCount() {
        const snapshot = await db.collection('ensembles').get();
        return Math.round(snapshot.size / 5) * 5; // Round to the nearest multiple of 5
    }

    async getAll() {
        const snapshot = await db.collection("ensembles").get()
        const documents = [];
        snapshot.forEach(doc => {
            const document = { id: doc.id, ...doc.data() };
            documents.push(document);
        })
        return documents.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }

    async get(id){
        const ensemble = await db.collection("ensembles").doc(id).get()
        return ensemble.data()
    }

    create(ensemble) {
        db.collection("ensembles").add(ensemble)
            .then((docRef) => {
                console.log(`Document written with ID: ${docRef.id}`)
            })
            .catch((error) => {
                console.error(`Error adding document: ${error}`)
            })
    }

    update(id, value) {
        db.collection("ensembles").doc(id).update(value)
            .then(docRef => {
                console.log(`Document updated: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }

    delete(id) {
        db.collection("ensembles").doc(id).delete()
            .then(docRef => {
                console.log(`Document deleted: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }
}

export default new EnsembleService();