<template>
  <nav class="navbar is-light" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item logo" href="/">
          <img src="https://pfdaa-app-uploads-public.s3.amazonaws.com/pfdaa_logo.png" alt="PFDAA Logo" />
        </a>
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          @click="toggleMenu"
          :class="{ 'is-active': isOpen }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <!-- Conditional rendering with v-if for mobile screens -->
      <div id="navbar" v-if="isMobile && isOpen" class="navbar-menu">
        <div class="navbar-start">
          <router-link to="/" class="navbar-item">Home</router-link>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">About</a>
            <div class="navbar-dropdown">
              <router-link to="/about" class="navbar-item">About Us</router-link>
              <router-link to="/board" class="navbar-item">Our Board</router-link>
              <router-link to="/join" class="navbar-item">Join the PFDAA</router-link>
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">Resources</a>
            <div class="navbar-dropdown">
              <router-link to="/books" class="navbar-item">Books</router-link>
              <router-link to="/costumes" class="navbar-item">Costumes</router-link>
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable" v-if="store.currentWithDues">
            <a class="navbar-link">Member Resources</a>
            <div class="navbar-dropdown">
              <router-link to="/marketplace" class="navbar-item">Marketplace</router-link>
              <router-link to="/resources" class="navbar-item">Resources</router-link>
            </div>
          </div>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              target="_blank"
              method="post"
              class="button-form"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="PRKGKRJB4CGGG" />
              <button class="button is-primary">Donate</button>
            </form>
            <div v-if="store.isLoggedIn" class="auth-buttons">
              <router-link to="/">
                <button class="button is-light" @click="logout">Log out</button>
              </router-link>
            </div>
            <div v-else class="auth-buttons">
              <router-link to="/login">
                <button class="button is-primary">Login</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>


      <div id="navbar" class="navbar-menu" v-if="!isMobile">
          <div class="navbar-start">
          <router-link to="/" class="navbar-item">Home</router-link>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">About</a>
            <div class="navbar-dropdown">
              <router-link to="/about" class="navbar-item">About Us</router-link>
              <router-link to="/board" class="navbar-item">Our Board</router-link>
              <router-link to="/join" class="navbar-item">Join the PFDAA</router-link>
              <router-link to="/sponsors" class="navbar-item">Our Sponsors</router-link>
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">Resources</a>
            <div class="navbar-dropdown">
              <router-link to="/books" class="navbar-item">Books</router-link>
              <router-link to="/costumes" class="navbar-item">Costumes</router-link>
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable" v-if="store.currentWithDues">
            <a class="navbar-link">Member Resources</a>
            <div class="navbar-dropdown">
              <router-link to="/marketplace" class="navbar-item">Marketplace</router-link>
              <router-link to="/resources" class="navbar-item">Resources by Region</router-link>
            </div>
          </div>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              target="_blank"
              method="post"
              class="button-form"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input type="hidden" name="hosted_button_id" value="PRKGKRJB4CGGG" />
              <button class="button is-primary">Donate</button>
            </form>
            <div v-if="store.isLoggedIn" class="auth-buttons">
              <router-link to="/">
                <button class="button is-light" @click="logout">Log out</button>
              </router-link>
            </div>
            <div v-else class="auth-buttons">
              <router-link to="/login">
                <button class="button is-primary">Login</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useUserStore } from "../../store";
export default {
  name: "Nav",
  setup() {
    let store = useUserStore();
    store.hydrateFromLocalStorage(); // Call this when the app loads
    return { store };
  },
  data() {
    return {
      isOpen: false,
      isMobile: window.innerWidth <= 768, // Detect if screen is mobile
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    logout() {
      this.store.logout();
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  display: flex;
  align-items: center;
  .navbar-item img {
    max-height: 60px; /* Reduce logo size for a modern look */
  }
}

.navbar-burger {
  color: #333; /* Ensure burger icon is visible */
  &:hover {
    background-color: transparent;
  }
}

.navbar-menu {
  .navbar-item {
    font-weight: bold;
    color: #2c3e50;
    &:hover {
      color: #d88d00;
    }
  }
}

.button {
  border-radius: 24px;
  padding: 0.75rem 1.5rem;
  font-weight: bold;
}

.button.is-primary {
  background-color: #ff6347;
  border-color: transparent;
  &:hover {
    background-color: #ff4500;
  }
}

.button.is-light {
  background-color: #f4f4f4;
  border-color: transparent;
  color: #333;
  &:hover {
    background-color: #eaeaea;
  }
}

@media screen and (max-width: 768px) {
  .navbar-menu {
    display: block;
    padding: 10px 0;
    .navbar-item {
      padding-left: 1.5rem;
    }
  }

  .auth-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .button-form {
    text-align: center;
  }
}
</style>