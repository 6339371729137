<template>
  <div id="app">
    <Nav />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Nav from './components/partials/Nav.vue';
import Footer from './components/partials/Footer';
export default {
  name: 'app',
  components: {
    Nav,
    Footer
  }
}
</script>

<style lang="scss">
/* Import Poppins and Raleway fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Raleway:wght@500;600&display=swap');

#app {
  font-family: 'Poppins', Helvetica, Arial, sans-serif; /* Poppins as default for body */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

/* Use Raleway for headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}

/* Optional: Customize link hover states for a modern touch */
a:hover {
  text-decoration: underline;
  color: #ff6347; /* Example: Change color on hover */
}
</style>
