import { defineStore } from "pinia";
import UserService from "./services/userService";

export const useUserStore = defineStore("users", {
  state: () => ({
    isLoggedIn: false,
    email: "",
    currentWithDues: false,
    group: "",
    isBoardMember: false,
    userId: "",
  }),

  getters: {
    isAuthenticated: (state) => state.isLoggedIn,
  },

  actions: {
    async login(email) {
      // Set initial state
      this.isLoggedIn = true;
      this.email = email;

      // Fetch additional user info from API
      const userinfo = await UserService.getByEmail(email);
      if (userinfo) {
        this.currentWithDues = userinfo.currentWithDues;
        this.group = userinfo.group;
        this.isBoardMember = userinfo.isBoardMember;
        this.userId = userinfo.id;
      }

      // Store login state in localStorage
      this.persistLoginState();
    },

    logout() {
      // Reset state on logout
      this.isLoggedIn = false;
      this.email = "";
      this.currentWithDues = false;
      this.group = "";
      this.isBoardMember = false;
      this.userId = "";

      // Remove login state from localStorage
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("userEmail");
    },

    // Check localStorage and restore the state if user is logged in
    hydrateFromLocalStorage() {
      const isAuth = localStorage.getItem("isLoggedIn");
      const email = localStorage.getItem("userEmail");

      if (isAuth && email) {
        this.login(email); // Auto-login if user info is in localStorage
      }
    },

    // Save login state to localStorage
    persistLoginState() {
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("userEmail", this.email);
    },
  },
});