<template>
  <div class="newsletter container" v-if="newsletterUrl">
    <h2 class="subtitle is-3">Read Our Most Recent Newsletter</h2>
  </div>
</template>
<script>
import NewsletterService from "../services/newsletterService";

export default {
  name: "Newsletter",
  data() {
    return {
      newsletterLink:""
    };
  },
  async mounted(){
    const last_newsletter = await NewsletterService.getLastNewsletter()
    this.newsletterLink = last_newsletter[0].link
    // TODO: Figure out how to implement PDF viewer
  }
};
</script>
<style lang="scss" scoped>
.newsletter {
  margin-top: 100px;
  text-align: center;
}
</style>