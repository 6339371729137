<template>
  <div class="event-card">
    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img :src="event.featuredImage" alt="Event image" />
        </figure>
      </div>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <p class="title is-4">{{ event.name }}</p>
            <p class="subtitle is-6">{{ event.date }}</p>
            <p class="subtitle is-6">{{ event.location }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["event"]
};
</script>

<style lang="scss" scoped>
.event-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  width: 100%;
  max-width: 350px; /* Ensure max width for consistency */
  margin: 0 auto; /* Center the card horizontally */

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // min-width: 250px;
    max-width: 270px;
  }

  .card-content {
    padding: 1rem;

    .title {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }

    .subtitle {
      font-size: 0.875rem;
      color: #6b6b6b;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .card-image img {
    border-radius: 10px 10px 0 0;
    object-fit: cover;
    width: 100%; /* Ensure the image spans the width of the card */
  }
}

</style>
